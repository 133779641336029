import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import BlogDetails from "@/components/blog-details";
import { Col, Container, Row } from "react-bootstrap";
import { graphql } from "gatsby";

const BlogDetailsPage = ({ data }) => {
  const { html } = data.markdownRemark
  const { title } = data.markdownRemark.frontmatter

  return (
    <MenuContextProvider>
      <Layout PageTitle="Blog Details Page">
        <HeaderOne />
        <PageHeader title="Blog Details" name="Blog" />
        {/* <BlogDetails /> */}
        <section className="blog_details_section section_padding">
          <Container>
            <Row>
              <Col>
                <div className="blog_left_box">
                  <div className="bl_share_img">
                    {/* <Img src='' alt="blog details" className="img-fluid" /> */}
                    <span className="blog_date">26 Nov</span>
                  </div>
                  <div className="blog_share_details blog_share_details__details">
                    <h1>{title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </div>
                </div>             
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default BlogDetailsPage;

export const query = graphql`
  query ProjectDetails($slug: String) {
    markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title        
      }
    }
  }`
